<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view v-if="ready" />
    </v-fade-transition>
    <v-dialog :value="!ready" max-width="400">
      <v-card>
        <v-card-title class="justify-center">
          Initializing {{ project ? project.name : 'project' }}
        </v-card-title>
        <v-card-text class="text-center">
          Please hold on...
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ProjectLayout',
  computed: {
    ...mapState('firebase', ['ready']),
    ...mapGetters(['project'])
  }
}
</script>
